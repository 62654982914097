.mapboxgl-ctrl-geocoder {
  width: 500px; /* set the width of the search bar */
}

#menu-wrapper {
  position: relative;
  top: 8px;
  z-index: 2;
}

#menu-icon {
  position: absolute;
  /* right: 10px; */
  font-size: 24px;
  cursor: pointer;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0.25em;
  right: 10px;
  transition:
    top 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  opacity: 1;
  padding: 0 18px 4px;
  z-index: 2;
}

#menu-icon.hidden {
  top: 5.5vh;
  opacity: 0;
}

#custom-drawer {
  z-index: 2;
  background-color: white;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    padding 0.2s ease-in-out,
    width 0.2s ease-in-out;
  padding: 0;
  width: 0;
  max-width: 380px;
  visibility: hidden;
  display: flex;
  flex-direction: column;
}

#custom-drawer.compressed {
  background-color: white;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    padding 0.2s ease-in-out,
    width 0.2s ease-in-out;
  padding: 0;
  height: 93vh;
  width: 0;
  max-width: 500px;
  visibility: hidden;
  display: flex;
  flex-direction: column;
}

#custom-drawer.open {
  opacity: 1;
  visibility: visible;
  width: 36rem;
}

#custom-drawer a {
  font-size: 13px;
  color: #404040;
  display: block;
  margin: 0;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}
#custom-drawer a:last-child {
  border: none;
}
#custom-drawer a:hover {
  background-color: #f8f8f8;
  color: #404040;
}
#custom-drawer a.active {
  background-color: #d8e1ec;
  color: #292929;
}
#custom-drawer a.active:hover {
  background: #a6acb4;
}

#map-layer-groupings a {
  text-align: center;
}
#map-layer-groupings span {
  vertical-align: middle;
  margin: 0 0.75em;
}

.section {
  margin-bottom: 1em;
}
.button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.button.active {
  background-color: #fc6a47;
}
.button div {
  display: inline-block;
}
.menu-option {
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  margin: 0;
  border: 1px solid transparent;
  color: #333;
  cursor: pointer;
  border-color: #ccc;
}
.menu-option:hover {
  background-color: #e0e0e0;
}
.menu-option.active {
  background-color: #c1c1c1;
}
.close-term {
  border-left: 2px solid grey;
  margin-left: 5px;
  padding-left: 5px;
}

.map-container {
  height: 100%;
}

.controller-section-label {
  font-size: 20px; /* adjust as needed */
  text-align: left;
  font-style: bold;
}

.layer-name {
  font-size: 14px; /* adjust to make smaller as per preference */
  margin-top: 5px;
  margin-bottom: 0; /* space between the image and the label */
}

.edit-mode-halo {
  position: relative;
}

.edit-mode-halo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 10px 10px orange inset;
  z-index: 1;
  pointer-events: none;
}

.base-map-popover {
  left: 10px;
  z-index: 1;
  bottom: 15px;
  cursor: pointer;
  position: absolute;
}
.base-map-popover img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #404040;
}
.base-map-popover span {
  bottom: 0;
  width: 100%;
  padding: 5px;
  color: #fff;
  display: block;
  font-size: 12px;
  position: absolute;
  text-align: center;
  border-radius: 0 0 8px 8px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.8);
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.merged-layers-dropdown > div:first-child {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px 0 !important;
  position: relative;
}

/* Only add right padding and arrow when has-options class is present */
.merged-layers-dropdown.has-options > div:first-child {
  padding-right: 24px !important;
}

.merged-layers-dropdown.has-options > div:first-child::after {
  content: '';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none;
}

.merged-layers-dropdown > svg {
  display: none;
}
