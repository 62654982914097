@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.header {
  height: 2.5rem;
  display: flex;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.notification,
.menu {
  color: aqua;
  background: none;
  border: none;
  background-color: #eff1ff;
}

.notification {
  padding: 0;
  border-radius: 0.5rem 0 0 0.5rem;
  color: #3f3f3f;

  width: 2.5rem;
  white-space: nowrap;
}

.menu {
  height: 100%;
  border-radius: 0 0.5rem 0.5rem 0;
}

.bell {
  margin-bottom: 0.1rem;
  pointer-events: none;
}

.btnContain {
  display: flex;
  pointer-events: none;
}

.profilePic {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  pointer-events: none;
  height: 30px;
  width: 30px;
}

.dropdown.active > .menu,
.notification:hover,
.menu:hover {
  background-color: #dde1ff;
}

.dropdown {
  position: relative;
}

.dropdownMenu {
  min-width: 10rem;
  width: max-content;
  pointer-events: none;
  background-color: #eff1ff;
  border-radius: 0.5rem;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-1rem);
  transition:
    opacity 150ms ease-in-out,
    transform 150ms ease-in-out;

  display: flex;
  flex-direction: column;
  z-index: 10;
}

.dropdown.active > .menu + .dropdownMenu {
  pointer-events: all;
  opacity: 1;
  transform: translateY(0);
}

.link {
  text-decoration: none;
  font-weight: 200;
  font-size: 1rem;
  color: #3f3f3f;
  cursor: pointer;
}

.link:hover {
  background-color: #dde1ff;
  color: #3f3f3f;
}

.custom-scrollbar {
  overflow-y: auto !important;
  max-height: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0;
}
