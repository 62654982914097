.menu-tabs button {
  color: #000;
  font-weight: 600;
}

.menu-tabs .MuiTabs-indicator {
  bottom: 2px;
}

.tab-content-wrapper {
  display: flex;
  overflow-y: hidden;
}

.tab-content-wrapper > div:first-child {
  width: 80%;
}

.tab-content-wrapper > div:last-child {
  width: 20%;
  background-color: rgba(217, 217, 217, 0.3);
  overflow-y: auto;
  max-height: 100%;
}

.tab-content-wrapper > div:last-child > div {
  overflow-y: auto !important;
}

.tab-content-wrapper > div:last-child button {
  font-size: 11px;
  padding: 16px 8px 8px;
  min-height: auto;
  width: 100%;
}

.tab-content-wrapper > div:last-child button svg {
  width: 40px;
  height: 40px;
  padding: 4px;
  color: #fff;
  margin-bottom: 6px;
  background-color: #d9d9d9;
}

.tab-content-wrapper > div:last-child button.Mui-selected {
  color: #5d36ca;
}

.tab-content-wrapper > div:last-child button.Mui-selected svg {
  background-color: #5d36ca;
}

.tab-content-wrapper > div:last-child::-webkit-scrollbar {
  width: 4px;
}

.tab-content-wrapper > div:last-child::-webkit-scrollbar-track {
  background: transparent;
}

.tab-content-wrapper > div:last-child::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
